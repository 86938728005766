<script>
import { app } from '@/main'
import { VMoney } from 'v-money'
import { mapState, mapActions } from 'vuex'
import { getStatusClass, getStatusText } from '@/support/utils/statusTagHelper'

const TRANSLATION = {
  headers: {
    payment: app.$t('finances.view:modal.headers.payment'),
    transaction: app.$t('finances.view:modal.headers.transaction'),
    summary: app.$t('finances.view:modal.headers.summary'),
    cart: app.$t('finances.view:modal.headers.cart')
  },

  labels: {
    createdAt: app.$t('finances.view:modal.labels.createdAt'),
    paymentDate: app.$t('finances.view:modal.labels.paymentDate'),
    paymentMethod: app.$t('finances.view:modal.labels.paymentMethod'),
    paymentInstallments: app.$t('finances.view:modal.labels.paymentInstallments'),
    totalValue: app.$t('finances.view:modal.labels.totalValue'),
    gateway: app.$t('finances.view:modal.labels.gateway'),
    value: app.$t('finances.view:modal.labels.value'),
    orderID: app.$t('finances.view:modal.labels.orderID'),
    paymentID: app.$t('finances.view:modal.labels.paymentID'),
    status: app.$t('finances.view:modal.labels.status'),
    statusFailedDescription: app.$t('finances.view:modal.labels.statusFailedDescription')
  },

  list: {
    credit_card: app.$t('finances:list.credit_card'),
    boleto: app.$t('finances:list.boleto')
  }
}

export default {
  name: 'ViewerPage',

  components: {
    Modal: () => import('@/components/general/Modal')
  },

  directives: { money: VMoney },

  data () {
    return {
      title: '',
      view: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false, // doesn't work with directive
        allowBlank: true
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),

    isStatusFinished () {
      return this.view.status && (this.view.status === 'finished' || this.view.status === 'paid')
    },

    isStatusFailed () {
      return this.view.status && this.view.status === 'failed'
    },

    canShowValues () {
      return this.isStatusFinished || this.isStatusFailed
    }
  },

  created () {
    this.setup()
    this.setFetching(true)

    Promise.all([ this.getFinanceById({ id: this.$route.params.id }) ]).finally(() => {
      this.setFetching(false)
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback'
    ]),

    ...mapActions('FinanceModule', [ 'attemptGetFinanceById' ]),

    setup () {
      this.TRANSLATION = TRANSLATION
    },

    closeModal () {
      this.$router.push({ name: 'finance.list' })
    },

    getFinanceById () {
      this.attemptGetFinanceById(this.$route.params.id)
        .then((response) => {
          if (response.length === 0) return

          this.title = response.id
          this.view = response
        }).catch(err => {
          console.error(err)

          this.setFeedback({ message: this.$t('finances.load:fetch.error') })
          this.closeModal()
        })
    },

    getStatusTagClass (status) {
      return getStatusClass(status)
    },

    getStatusTagText (status) {
      return getStatusText(status)
    },

    getStatusFailedDescription (order) {
      let message = ''

      if (order.data !== undefined && order.data.length) {
        message += order.data[0].message

        if (order.data[0].details !== undefined && order.data[0].details.length) {
          message += ` - ${order.data[0].details[0].description}`
        }
      }

      return message
    },

    getLabelProduct (item) {
      return item.quantity + 'x - ' + (item.solution ? item.solution.name : item.program ? item.program.name : '')
    },

    getPriceProduct (item) {
      return this.formatCurrency(item.price)
    }
  }
}
</script>

<template>
  <Modal
    :active="!fetching"
    :cancel-label="$t('global:back')"
    @close="closeModal"
  >
    <div
      v-if="view"
      class="modal-form modal-view-finance"
    >
      <span class="modal-subtitle">{{ $t('finances.view:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ formatTransactionId(view.id) }}
      </h2>
      <div class="modal-status">
        <span
          class="status-tag"
          :class="getStatusTagClass(view.status)"
        >{{ getStatusTagText(view.status) }}</span>
      </div>
      <div class="finance-view">
        <div class="finance-view-body">
          <div class="view-body view-body--left">
            <h2 class="view-header">
              {{ TRANSLATION.headers.payment }}
            </h2>
            <div class="view-body-content">
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.createdAt }}
                </div>
                <div class="item-value">
                  {{ formatDateTime(view.createdAt) }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.paymentDate }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ formatDateTime(view.paymentDate) }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.paymentMethod }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ TRANSLATION.list[view.paymentMethod] }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.paymentInstallments }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ view.products && view.products.length > 0 ? formatInstallments(view.products[0].data) : '' }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.totalValue }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  <div class="money-value">
                    {{ formatCurrency(view.value) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="view-body view-body--right">
            <h2 class="view-header">
              {{ TRANSLATION.headers.transaction }}
            </h2>
            <div class="view-body-content">
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.gateway }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ view.providers[0].provider.name }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.value }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  <div class="money-value">
                    {{ formatCurrency(view.value) }}
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.orderID }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ view.id }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.paymentID }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ view.transactionId }}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                  {{ TRANSLATION.labels.status }}
                </div>
                <div
                  v-if="canShowValues"
                  class="item-value"
                >
                  {{ getStatusTagText(view.status) }}
                </div>
              </div>
              <div
                v-if="view.status === 'failed'"
                class="item"
              >
                <div class="item-label">
                  {{ TRANSLATION.labels.statusFailedDescription }}
                </div>
                <div class="item-value">
                  {{ getStatusFailedDescription(view) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="finance-view-footer">
          <h2 class="view-header">
            {{ canShowValues ? TRANSLATION.headers.summary : TRANSLATION.headers.cart }}
          </h2>
          <div class="view-body">
            <ul>
              <li
                v-for="(summaryItem, summaryItemIndex) in view.products"
                :key="summaryItemIndex + ':' + summaryItem.id"
                class="summary-item"
              >
                <div class="summary-item--solution">
                  {{ getLabelProduct(summaryItem) }}
                </div>
                <div
                  v-if="summaryItem.solution || summaryItem.program"
                  class="summary-item--value"
                >
                  <div class="money-value text-right">
                    {{ getPriceProduct(summaryItem) }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" src="../assets/scss/Viewer.scss"></style>
